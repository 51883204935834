import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 532,
			tabName: "Аналитика",
			desktopTitle: "Онлайн-система управления кальянной",
			desktopContent:
				"Система автоматизации кальянной Quick Resto позволяет отслеживать и анализировать все процессы работы: от приёмки товаров на точке продаж до определения самых продаваемых позиций и расчёта зарплат сотрудников.",
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/hookah-accounting-1.png"
					alt="система автоматизации кальянной"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 532,
			tabName: "Склад",
			desktopTitle: "Складские операции на кассовом терминале",
			desktopContent:
				"В программе учёта Quick Resto можно проводить складские операции прямо с кассового терминала: доверь персоналу создание приходных накладных и&nbsp;инвентаризацию. Все результаты будут доступны в облачном бэк-офисе с любого устройства.",
			detailLink: pagesLinks.warehouse,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/hookah-accounting-2.png"
					alt="складской учет в кальянной"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 532,
			tabName: "Отчёты",
			desktopTitle: "Увеличивай средний чек и свою прибыль",
			desktopContent:
				"Отчёты по&nbsp;продажам покажут, какие блюда приносят больше прибыли бизнесу. Отчёты по&nbsp;сотрудникам дадут возможность увидеть, кто продаёт лучше. Автоматический расчёт зарплат добавит прозрачности и&nbsp;мотивации для персонала. А&nbsp;история заказов и&nbsp;база гостей в&nbsp;CRM-системе кальянной расскажут, когда приходят постоянные гости и&nbsp;какие акции их&nbsp;привлекают.",
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/hookah-accounting-3.png"
					alt="abc-анализ"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}

import React from 'react'
import classNames from 'classnames'

import { requestForAutoOpenHookahFaster, } from '../../apiRequests/callToAction'
import DefaultLayout from '../../layout/default'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'
import AutomationMenu from '../../components/_V2/AutomationMenu'

import ModesFeatures from '../../pagesSections/avtomatizaciya-kalyannoj/ModesFeatures'
import AvailableFeatures from '../../pagesSections/avtomatizaciya-kalyannoj/AvailableFeatures'
import InterfaceFeatures from '../../pagesSections/avtomatizaciya-kalyannoj/InterfaceFeatures'
import AccountingFeatures from '../../pagesSections/avtomatizaciya-kalyannoj/AccountingFeatures'
import HookahCtaForm from '../../pagesSections/avtomatizaciya-kalyannoj/HookahCtaForm'
import ProductPresentation from '../../pagesSections/avtomatizaciya-kalyannoj/ProductPresentation'
import Presentation
  from '../../pagesSections/avtomatizaciya-kalyannoj/ProductPresentation/assets/hookah-presentation.png'
import CtaForm from '../../pagesSections/index/CtaForm'

import featuresData from '../../pages-data/_V2/automatization/hookah/features'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import AccountingFeaturesIntegrations from "../../pagesSections/intergations/AccountingFeatures"
import ProductsFeatures from "../../pagesSections/intergations/ProductsFeatures"
import AdditionalServices from "../../pagesSections/index/AdditionalServices"

export default function PageCoffeehouse() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'Программа автоматизации кальянной ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-kalyannoj/',
    },
    {
      property: 'og:title',
      content: 'CRM-система для автоматизации кальянной — комплексная программа учета | Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Программа автоматизации кальянной ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title="CRM-система для автоматизации кальянной — комплексная программа учета | Quick Resto"
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-kalyannoj/"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index === 0)}
        className={pageStyles.pageSection}
      />

      <ModesFeatures className={pageStyles.pageSection} />

      <AvailableFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index === 1)}
        className={pageStyles.pageSection}
      />

      <InterfaceFeatures className={pageStyles.pageSection} />

      <HookahCtaForm
        className={pageStyles.pageSection}
        request={requestForAutoOpenHookahFaster}
      />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index === 2)}
        className={pageStyles.pageSection}
      />

      <AccountingFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index < 5)}
        className={pageStyles.pageSection}
      />

      <AccountingFeaturesIntegrations className={pageStyles.pageSection} theme="white" />

      <ProductsFeatures className={pageStyles.pageSection} theme="white" />

      <AdditionalServices className={styles.additionalServices} theme="white" />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index >= 5)}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
    </DefaultLayout>
  )
}

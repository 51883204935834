import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 510,
			tabName: "Типы оплат",
			desktopTitle: "Приём различных типов оплат",
			desktopContent:
				"Оплата наличными или картой и последующий корректный учёт — именно то, чего так не хватало малым форматам бизнеса.  Касса, которая не тормозит и принимает все виды оплат, — в программе для автоматизации кальянной.",
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/hookah-modes-3.png"
					alt="кассовая система для фудтрака"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 510,
			tabName: "Автономность",
			desktopTitle: "Автономность и офлайн-режим",
			desktopContent:
				"Система автоматизации кальянной Quick Resto автономна: работает без интернета и даже электричества. Будь уверен — все продажи и списания будут корректно учтены, когда появится связь с сервером.",
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/hookah-modes-1.png"
					alt="касса для кальянной"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}

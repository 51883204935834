import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import loyaltyStyles from "../common/loyalty-feature/styles.module.scss"
import indicatorsStyles from "../common/indicators-feature/styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Программа автоматизации для кальянной в облаке",
		description: (
			<>
				<p>
					Управление продажами и отслеживание финансовых результатов теперь не просто
					удобно, с Quick Resto это ещё и современно и технологично.
				</p>
				<p>
					Единая экосистема объединяет интуитивно понятный кассовый
					терминал на планшете, мобильное приложение с функциями предзаказа и
					push-уведомлений, личный кабинет с полной статистикой продаж и
					аналитикой за прошедшие периоды, систему электронной очереди и QR-меню.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/hookah-feature-1.png"
				alt="программа автоматизации кальянной"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",
	},
	{
		title: "Удобная система управления кальянной",
		description: (
			<p>
				Расширяй возможности бизнеса с дополнительными приложениями и оборудованием.
				К системе автоматизации кальянной можно подключить тикет-принтер для кухни,
				дисплей повара и дисплей покупателя, а также экран электронной очереди.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/hookah-feature-2.png"
				alt="программа управления кальянной"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",
	},
	{
		title: "Электронное меню для кальянной",
		description: (
			<p>
				За&nbsp;один день запустим сайт с&nbsp;меню кальянной.
				Гости будут попадать на&nbsp;него по&nbsp;QR-коду, который можно разместить
				у&nbsp;кассы, на&nbsp;входной двери, столиках или в&nbsp;уголке
				потребителя. Добавь ссылку на электронное меню в&nbsp;соцсети,
				карты&nbsp;Яндекса, Google и&nbsp;2ГИС, чтобы захватывать больше
				новых&nbsp;посетителей.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/hookah-feature-3.png"
				alt="электронное меню для кальянной"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.wlShop.href,
	},
	{
		title: "Программа лояльности, как инструмент для привлечения гостей",
		description: (
			<p>
				Запускай акции на&nbsp;нужные позиции табака, кальянов или блюд,
				настраивай предложения на&nbsp;комбо-наборы и&nbsp;скидки по&nbsp;времени.
				Используй возможности CRM для кальянной и&nbsp;мобильного приложения для
				гостей&nbsp;&mdash; отправляй персональные предложения. Сегментируй аудиторию
				по&nbsp;чекам и&nbsp;настраивай push-уведомления.
				<br />
				<br />
				Гости смогут оформлять предзаказы и&nbsp;доставку, следить в&nbsp;приложении
				за&nbsp;уровнем бонусов и&nbsp;текущими акциями.
			</p>
		),

		img: (
			<StaticImage
				className={loyaltyStyles.image}
				src="../common/loyalty-feature/loyalty-feature.png"
				alt="программы лояльности для кальянной"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: loyaltyStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Показатели всегда под рукой",
		description: (
			<p className={styles.smallDescription}>
				Используй мобильное приложение Quick Resto Manager для быстрого удалённого
				доступа к&nbsp;статистике работы заведения. Сможешь оценить эффективность
				программ лояльности, работу сотрудников, прибыльность блюд и&nbsp;многое другое.
			</p>
		),

		img: (
			<StaticImage
				className={indicatorsStyles.image}
				src="../common/indicators-feature/indicators-feature.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: indicatorsStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
	},
	{
		title: "Внедрение автоматизации кальянной под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]

import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Компактность",
			desktopTitle: "Скромные габариты",
			desktopContent:
				"У заведения маленькое помещение, нужно эффективно использовать каждый сантиметр стойки администратора? С программой учёта для кальянной Quick Resto не потребуется отдельная комната для сервера, а кассовый терминал займёт совсем немного места на столе.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/hookah-available-1.png"
					alt="система учёта для кальянной"
					objectFit="cover"
					objectPosition="center 80%"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Мультиплатформенность",
			desktopTitle: "Мульти-платформенность",
			desktopContent:
				"Программу для кальянных Quick Resto можно установить на устройства с разными операционными системами: кассовый терминал работает на iOS, ОС Windows и Android, а также на терминалах Эвотор.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/hookah-available-3.png"
					alt="программа для кальянной"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}

import React from "react"
import data from "./data"
import AutomationCtaA from "../../../components/_V2/AutomationCtaA"

interface Props {
	className?: string
	request?: Function
}

export default function HookahCtaForm(props: Props) {
	return (
		<AutomationCtaA
			data={data}
			className={props.className}
			request={props.request}
		/>
	)
}
